import "./scss/styles.scss";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

function mainCarousel() {
    jQuery('.card-container-carousel').owlCarousel({
        loop: true,
        items: 3,
        nav: true,
        autoplay: true,
        responsiveClass: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,

            },
            1400: {
                items: 3
            }
        }
    });
}

function sliderOurModels() {

    let elementCounter = jQuery('#nuestros-modelos .nuestros-modelos-text-container .container-carousel .nuestros-modelos-text-information-container .nuestros-modelos-text-information').length;
    if (elementCounter >= 3) {
        console.log("hay", elementCounter, "elementos");
        jQuery('#nuestros-modelos .nuestros-modelos-text-container .container-carousel .nuestros-modelos-text-information-container').addClass("owl-carousel").owlCarousel({
            loop: true,
            items: 3,
            nav: true,
            autoplay: true,
            responsiveClass: true,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            margin: 11,
            responsive: {
                0: {
                    items: 1,
                }
            }
        });
    } else {
        jQuery('#nuestros-modelos .nuestros-modelos-text-container .container-carousel .nuestros-modelos-text-information-container').trigger("destroy.owl.carousel").removeClass("owl-carousel");
    }

}







$(document).ready(function () {
    console.log("Ready")
    mainCarousel();
    sliderOurModels();

    var wWidth = $(window).width();

    $('.faq h3').click(function () {
        var current = $(this).parent();
        console.log(current)
        var isActive = current.hasClass('active');
        $('.faq .answer').slideUp();
        $('.faq').removeClass('active');

        if (!isActive) {
            current.addClass('active');
            current.find('.answer').slideDown();
        }
    });

    $('nav a').click(function () {
        $('nav a').removeClass('active');
        $(this).addClass('active');
    });

    $(window).scroll(function () {
        if (wWidth > 768) {
            if ($(this).scrollTop() > 50) {
                $('header').addClass('navbar-solid');
                $('header').removeClass('navbar-transparent');
            } else {
                $('header').addClass('navbar-transparent');
                $('header').removeClass('navbar-solid');
            }
        }
    });

    $('#new-cascadia-btn').on('click', function () {
        $('html, body').animate({
            scrollTop: $('#dejanos-tus-datos').offset().top
        }, 800);
    });


    $('.navbar-burger').click(function () {
        console.log("click")
        $(this).toggleClass('active');
        $('.navbar-menu').toggleClass('active');
    });
    
    var $navbarLinks = $("nav ul li a");

    // Manejador de eventos para hacer clic en los enlaces del navbar
    $navbarLinks.on("click", function (event) {
        // Evita el comportamiento predeterminado del enlace
        event.preventDefault();

        // Obtiene el ID del elemento asociado al enlace
        var target = $(this).attr("href");

        // Realiza la animación de desplazamiento suave hacia el elemento objetivo
        $("html, body").animate({
            scrollTop: $(target).offset().top - 100
        }, 1000);

        // Cierra menú hamburguesa
        $('.navbar-burger').trigger("click")
    });

    $("#openModal").on("click", function () {
        $(".modal").fadeIn();
        $('body').addClass('modal-open');
    });

    // Cerrar modal al hacer clic en la "X" de cierre
    $(".close").on("click", function () {
        $(".modal").fadeOut();
        $('body').removeClass('modal-open');
    });


    var $navbarLinks = $("nav ul li a");

    // Manejador de eventos para hacer clic en los enlaces del navbar
    $navbarLinks.on("click", function (event) {
        // Evita el comportamiento predeterminado del enlace
        event.preventDefault();

        // Obtiene el ID del elemento asociado al enlace
        var target = $(this).attr("href");

        // Realiza la animación de desplazamiento suave hacia el elemento objetivo
        $("html, body").animate({
            scrollTop: $(target).offset().top - 100
        }, 1000);

        // Cierra menú hamburguesa
        $('.navbar-burger').trigger("click")
    });

    hbspt.forms.create({
        region: "na1",
        portalId: "3942150",
        formId: "b84730b0-f083-482b-8602-8defe795f49b",
        target: "#hubspot-form"
    });
})
